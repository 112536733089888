// Functionality for Distributor and Pro Search
jQuery(document).ready(function($){

    if($('[data-certified-grid]').length){

    // Function to build professional blocks
    function buildProfs(el, distance){
        console.log(el);
        var html = '';
        html += `
        <div class="certified-pro" data-distance-from="` + distance + `">
            <div class="certified-map-container">
            <div class="certified-map">
            <div data-iframe-replace="https://maps.google.com/maps?q=` + el.lat + `,` + el.lng + `&hl=es;z=14&amp;output=embed" style="display: none;"></div>
            </div>
            </div>
            <div>
        `;
        if(el.title !== '' && typeof el.title !== 'undefined'){
            html += `<p class="mb-2 mb-md-3"><strong>` + el.title + `</strong></p>`;
        }
        // if(el.address !== '' && typeof el.address !== 'undefined'){
        //     html += `<p><br>` + el.address;
        // }
        // if(el.address_2 !== '' && typeof el.address_2 !== 'undefined'){
        //     html += `<br>` + el.address_2;
        // }
        if(el.contact_name !== '' && typeof el.contact_name !== 'undefined'){
            html += `<p><strong>Contact: </strong> ` + el.contact_name + `</p>`;
        }
        if(el.street_address !== '' && typeof el.street_address !== 'undefined'){
            html += `<p>` + el.street_address + `</p>`;
        }
        html += `<p>`;
        if(el.city !== '' && typeof el.city !== 'undefined'){
            html += el.city + `, `;
        }
        if(el.state !== '' && typeof el.state !== 'undefined'){
            html += el.state;
        }
        if(el.country !== '' && typeof el.country !== 'undefined' && el.country !== 'United States'){
            html += `, ` + el.country;
        }
        if(el.zip !== '' && typeof el.zip !== 'undefined'){
            html += ` ` + el.zip;
        }
        html += `<br></p><p><a class="certified-phone" href="tel:` + el.phone + `">` + el.phone + `</a><br><br></p>`;
        if(el.website !== '' && typeof el.website !== 'undefined'){
            html += `<p class="mb-1"><a class="certified-site" href="` + el.website + `" target="_blank">` + el.website + `</a><br></p>`;
        }
        if(el.email !== '' && typeof el.email !== 'undefined'){
            html += `<p><a href="mailto:` + el.email + `">` + el.email + `</a></p>`;
        }
        html += `       
            </div>
        </div>`;

        $('[data-certified-grid]').append(html);
    }
    
    var downloadPdf = '#';

    // Show all professionals
    var jsonUrlOld = "{{ 'distributors.json' | asset_url }}";
    // var jsonUrl = 'https://store-2es2hypwjv.mybigcommerce.com/content/certified-pros-and-distributors/distributors.json';

    var jsonUrl = 'https://data.mbstonepro.com/wp-json/mb-stone/v1/distributors?v=14';

    // downloadPdf = "{{ 'distributors.pdf' | asset_url }}";
    // downloadPdf = 'https://store-2es2hypwjv.mybigcommerce.com/content/certified-pros-and-distributors/distributors.pdf';
    function mainBuild(){
        $.getJSON(jsonUrl, function(data){
            console.log(data);
            // var profs = data.individuals;

            var profs = data.posts;
            $.each(profs, function(key, el){
                buildProfs(el, 999);
            });
        });
    }

    // Show all professionals to start
    // mainBuild();

    // Haversine Formula
    function haversineDistance(lat1, lng1, lat2, lng2, isMiles){
      function toRad(x) {
        return x * Math.PI / 180;
      }
      var R = 6371; // km
      var x1 = lat2 - lat1;
      var dLat = toRad(x1);
      var x2 = lng2 - lng1;
      var dLon = toRad(x2)
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      if(isMiles) d /= 1.60934;
      return d;
    }

    // Press Button to Search
    $('#button-certified-search').click(function(){

        var btn = $(this);
        if(!btn.is('[data-lat]') || btn.attr('data-lat') == ''){
            // $('[data-certified-grid]').html('<p class="search-result-helper">Sorry, we couldn\'t find any results that matched your search criteria. If you\'re still having trouble, you can <a href="' + downloadPdf + '" download>download a complete PDF of the results</a>.</p>');
            return;
        }
        if(!$('#certified-search').val()){
            $('[data-certified-grid]').html('');
            mainBuild();
            return;
        }
        $('[data-certified-grid]').addClass('certified-grid--thinking');
        var theLat1 = $(this).attr('data-lat');
        var theLng1 = $(this).attr('data-lng');
        $('[data-certified-grid]').html('');
        $.getJSON(jsonUrl, function(data){
            // var profs = data.individuals;
            console.log(data);

            var profs = data.posts;
            $.each(profs, function(key, el){
                var theLat2 = el.lat;
                var theLng2 = el.lng;
                var distance = haversineDistance(theLat1, theLng1, theLat2, theLng2, true);
                console.log('Distance: ' + distance);
                buildProfs(el, distance);
            });
            var items = $('[data-certified-grid] *[data-distance-from]');
            items.sort(function(a, b){
                return +$(a).attr('data-distance-from') - +$(b).attr('data-distance-from');
            });
            items.appendTo('[data-certified-grid]');
            if($('*[data-distance-from]').eq(0).attr('data-distance-from') > 100){
                $('*[data-distance-from]:nth-child(4)').nextAll('*[data-distance-from]').remove();
                $('[data-certified-grid]').prepend('<p class="search-result-helper">No nearby results were found. The closest 4 results are listed below.</p>');
            } else {
                // console.log('All good');
                $('*[data-distance-from]').each(function(){
                    var map = $(this);
                    var howFar = parseInt($(this).attr('data-distance-from'));
                    if(howFar > 100){
                        map.remove();
                    }
                });
                $('[data-certified-grid]').prepend('<p class="search-result-helper">Results within 100 miles of your location are shown below.</p>');
            }
            $('[data-certified-grid]').removeClass('certified-grid--thinking');
            $('*[data-iframe-replace]').each(function(){
               var mapSrc = $(this).attr('data-iframe-replace');
               $(this).after('<iframe src="' + mapSrc + '" frameborder="0" style="border:0;"></iframe>');
            });
        });
    });

    $('.certified-search > div').fadeIn();

    }

});

// Non jQuery Scripts

// Simulate click
var simulateClick = function(elem){
    // Create our event (with options)
    var evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
};

// Detect Enter click
var pac_input = document.getElementById('certified-search');
(function pacSelectFirst(input) {
    // store the original event binding function
    var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
    function addEventListenerWrapper(type, listener) {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.
        if (type == "keydown") {
            var orig_listener = listener;
            listener = function(event) {
                var suggestion_selected = $(".pac-item-selected").length > 0;
                if (event.which == 13 && !suggestion_selected) {
                    var simulated_downarrow = $.Event("keydown", {
                        keyCode: 40,
                        which: 40
                    });
                    orig_listener.apply(input, [simulated_downarrow]);
                    
                    setTimeout(function(){
                        simulateClick(document.getElementById('button-certified-search'));
                    }, 350);
                    
                }
                orig_listener.apply(input, [event]);
            };
        }
        _addEventListener.apply(input, [type, listener]);
    }
    input.addEventListener = addEventListenerWrapper;
    input.attachEvent = addEventListenerWrapper;
    var autocomplete = new google.maps.places.Autocomplete(input);
    
})(pac_input);

// Initialize Autocomplete
function initialize(){
    var input = document.getElementById('certified-search');
    var autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', function(){
        $('#button-certified-search').attr('data-lat', '');
        $('#button-certified-search').attr('data-lng', '');
        var place = autocomplete.getPlace();
        if(typeof place.geometry !== 'undefined'){
            var lat = place.geometry.location.lat();
            var lng = place.geometry.location.lng();
            $('#button-certified-search').attr('data-lat', lat);
            $('#button-certified-search').attr('data-lng', lng);
        } else {
            $('#button-certified-search').attr('data-lat', '');
            $('#button-certified-search').attr('data-lng', ''); 
        }
    });
}
google.maps.event.addDomListener(window, 'load', initialize);